var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('ValidationObserver',{ref:"retrievalQueryRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('portal',{attrs:{"to":"page-title"}},[_vm._v(" "+_vm._s(_vm.actionText)+" Retrieval Query ")]),_c('portal',{attrs:{"to":"loading"}},[(_vm.loading)?_c('OrbitSpinner',{attrs:{"animation-duration":1000,"size":32,"color":"#9e4526"}}):_vm._e()],1),_c('portal',{attrs:{"to":"page-actions"}},[_c('div',{staticClass:"flex flex-row space-x-2"},[_c('RouterLink',{staticClass:"flex items-center px-4 py-2 space-x-2 text-sm font-medium leading-5 transition duration-150 ease-in-out bg-white border rounded-md shadow-sm text-neutral-700 hover:bg-neutral-100 focus:outline-none focus:ring-neutral focus:border-neutral-700 active:bg-neutral-100",attrs:{"to":{ name: 'retrieval' }}},[(_vm.hasChanges)?_c('XIcon',{staticClass:"w-5 h-5 text-neutral-500"}):_c('ChevronLeftIcon',{staticClass:"w-5 h-5 text-neutral-500"}),_c('span',[(_vm.hasChanges)?[_vm._v("Cancel")]:[_vm._v("Back")]],2)],1),_c('button',{staticClass:"flex flex-row items-center px-4 py-2 space-x-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-primary focus:border-primary-700 active:bg-primary-700 disabled:cursor-not-allowed disabled:opacity-50",attrs:{"type":"button","disabled":_vm.loading || invalid},on:{"click":_vm.save}},[_c('CheckIcon',{staticClass:"w-5 h-5"}),_c('span',[_vm._v("Save")])],1)],1)]),_c('div',{staticClass:"flex flex-col flex-grow"},[(!_vm.loading)?_c('div',{staticClass:"container px-6 pb-16 mx-auto my-4 space-y-6"},[_c('div',{staticClass:"flex flex-col space-y-8"},[_c('FormBlock',{attrs:{"title":"Retrieval Query Details","description":"Basic information about the Retrieval Query"}},[_c('label',{staticClass:"block"},[_c('ValidationProvider',{attrs:{"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-xs font-bold tracking-wider uppercase text-neutral-700",class:{ 'text-red-600': errors.length > 0 }},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.retrievalQuery.title),expression:"retrievalQuery.title"}],staticClass:"block w-full mt-1 text-sm form-input",class:{ 'border-red-600': errors.length > 0 },attrs:{"name":"name","type":"text","placeholder":"Enter Retrieval Query title"},domProps:{"value":(_vm.retrievalQuery.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.retrievalQuery, "title", $event.target.value)}}}),(errors.length > 0)?_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('label',{staticClass:"block"},[_c('ValidationProvider',{attrs:{"rules":"required|max:4096"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-xs font-bold tracking-wider uppercase text-neutral-700",class:{ 'text-red-600': errors.length > 0 }},[_vm._v(" Description ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.retrievalQuery.description),expression:"retrievalQuery.description"}],staticClass:"block w-full mt-1 text-sm resize-none form-textarea",class:{ 'border-red-600': errors.length > 0 },attrs:{"name":"description","rows":"3","placeholder":"Enter a short description for your retrieval query"},domProps:{"value":(_vm.retrievalQuery.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.retrievalQuery, "description", $event.target.value)}}}),(errors.length > 0)?_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('FormBlock',{attrs:{"title":"Retrieval Accessibility","description":"The retrieval accessibility mechanism"}},[_c('label',{staticClass:"block"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex flex-col space-y-1"},[_c('div',{staticClass:"flex flex-col space-y-6"},_vm._l((_vm.retrievalAccessibilityTypes),function(accessibility){return _c('div',{key:accessibility.key},[_c('div',{staticClass:"flex items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.retrievalQuery.accessibility),expression:"retrievalQuery.accessibility"}],staticClass:"text-primary-600 form-radio disabled:text-neutral-400 disabled:cursor-not-allowed",attrs:{"id":accessibility.key,"type":"radio","disabled":!_vm.isNew ||
                                                        (_vm.retrievalQuery.configuration &&
                                                            _vm.retrievalQuery.configuration.datasets &&
                                                            _vm.retrievalQuery.configuration.datasets.length > 0)},domProps:{"value":accessibility.key,"checked":_vm._q(_vm.retrievalQuery.accessibility,accessibility.key)},on:{"change":function($event){return _vm.$set(_vm.retrievalQuery, "accessibility", accessibility.key)}}}),_c('label',{staticClass:"ml-3 text-sm font-bold tracking-wide uppercase text-neutral-700",attrs:{"for":accessibility.key}},[_vm._v(" "+_vm._s(accessibility.name)+" ")])]),_c('p',{staticClass:"block pl-3 ml-4 text-sm text-neutral-500"},[_vm._v(" "+_vm._s(accessibility.description)+" ")])])}),0),(errors.length > 0)?_c('span',{staticClass:"ml-6 text-sm text-red-600"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)})],1)])],1)]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }